<template>
  <div>
    <b-modal id="modal-1" title="Información" ok-only ok-title="Aceptar" no-close-on-esc no-close-on-backdrop
      header-bg-variant="dark" header-text-variant="light">
      <p style="font-size: 1rem">
        Señor usuario tenga en cuenta que si uno de sus exámenes es glicemia pre
        y post o curva de glicemia, debe agendar su cita solo en horario de 6:00
        am - 7:00 am.
      </p>
      <p style="font-size: 1rem" v-show="placeSelected != 'Barrancabermeja'">
        Para la programación de Espermograma por favor seleccionar la Sede Principal Calle 45 # 29 - 55.
      </p>
      <p style="font-size: 1rem;">
        Le recordamos que deberá asistir a su toma de muestra máximo 10 minutos antes de la hora asignada.
      </p>
    </b-modal>
    <b-container>
      <b-row>
        <b-col>
          <h5 style="font-size: 1rem">
            {{ nomUser }}, seleccione el horario que desea agendar para la sede
            <b>{{ placeAdress }}</b>:
          </h5>
          <h5 style="font-size: 1rem; color: red">
            <b>Le recordamos que deberá asistir a su toma de muestra máximo 10 minutos antes de la hora asignada y el
              tiempo de atención estimado es de 30 minutos</b>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Horarios disponibles para:<h5><b><u>{{ dateSelectedFormated }}</u></b></h5>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col class="text-center">
          <b-icon icon="arrow-clockwise" animation="spin" font-scale="4" v-if="isBussy"></b-icon>
          <b-table v-if="!isBussy" hover bordered :items="schedules" :per-page="perPage" :current-page="currentPage"
            :tbody-tr-class="rowClass" :fields="fields" table-variant="primary" head-variant="dark" small
            style="font-size: 0.8rem">
            <template #cell(schedule)="data">
              <b-container>
                <b-row>
                  <b-col class="pt-2" style="font-size: 1.2rem">
                    {{ data.value }}
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #cell(state)="data">
              <b-button type="button" variant="success" v-if="data.value > 0" @click="goStepConfirmReserve(data.item)"
                block :id="data.item.schedule">
                Agendar
              </b-button>
              <p v-else class="pt-1">
                <b>Ocupado</b>
              </p>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
            v-if="rows > perPage" first-text="<<" prev-text="<" next-text=">" last-text=">>" pills align="center"
            small></b-pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button :pressed="true" type="button" variant="primary" @click="goStepDate" style="font-size: 0.8rem">
            <!-- <div class="h5 mb-2 mt-1"> -->
            <b-icon icon="calendar-date" aria-hidden="true" class="mr-2"></b-icon>
            <!-- </div> -->
            Cambiar Fecha</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
var moment_tz = require("moment-timezone");
export default {
  name: "StepSchedule",
  components: {},
  data() {
    return {
      perPage: 8,
      currentPage: 1,
      fields: [
        { key: "schedule", label: "Horario" },
        { key: "state", label: "Estado" },
      ],
      isBussy: false,
    };
  },
  computed: {
    ...mapState("users", ["userData"]),
    ...mapState("reserves", ["schedules", "placeSelected", "dateSelected"]),
    ...mapState("places", ["places"]),
    rows() {
      return this.schedules.length;
    },
    nomUser() {
      return this.userData.name + " " + this.userData.lastname;
    },
    placeAdress() {
      return (
        this.placeSelected +
        " (" +
        this.places.filter((el) => el.name == this.placeSelected)[0].adress +
        ")"
      );
    },
    dateSelectedFormated() {
      return moment_tz(this.dateSelected).tz("America/Bogota").format('dddd D [de] MMMM [de] YYYY')
    }
  },
  async mounted() {
    this.$bvModal.show("modal-1");
    if (this.userData.document == "") {
      this.$router.replace("/");
    }
    this.isBussy = true;

    // if (this.placeSelected == "Principal (Alianza Diagnóstica S.A.)") {
    //   this.getAvailabilityReservesPrincipal(this.dates).then((rta) => {
    //     this.isBussy = false;
    //   });
    // }else{
    await this.getAvailabilityReserves(this.dateSelected)
    this.isBussy = false;
    // }

    // 24042021 - Comentado para ajuste de cambio de horarios
    // if (this.placeSelected == "Principal (Alianza Diagnóstica S.A.)") {
    //   this.getAvailabilityReservesPrincipal(this.dates).then((rta) => {
    //     this.isBussy = false;
    //   });
    // } else {
    //   this.getAvailabilityReserves().then((rta) => {
    //     // console.log("rta", this.schedules);
    //     //aqui recorrer el array de horarios
    //     //por cada item evaluar moment(this.schedules.schedule).fromNow();

    //     this.isBussy = false;
    //   });
    // }
  },
  methods: {
    ...mapActions("reserves", [
      "setReserveSchedule",
      "getAvailabilityReserves",
      "getAvailabilityReservesPrincipal",
    ]),
    goStepConfirmReserve(data) {
      this.setReserveSchedule(data.schedule);
      this.$router.push("StepConfirmReserve");
    },
    goStepDate() {
      this.$router.go(-1);
    },
    rowClass(item, type) {
      moment.locale("es");
      if (!item || type !== "row") return;

      let initialSchedule =
        this.dateSelected + " " + item.schedule.substring(0, 5);
      let timeForSchedule = moment(moment(initialSchedule)).fromNow();
      let isMinutes = timeForSchedule.indexOf("minutos");
      let inPast = timeForSchedule.indexOf("hace"); //>= 0 ? true : false;
      let minutesToSchedule = moment(moment(initialSchedule))
        .fromNow(true)
        .substring(0, 2);
      // let minutes =
      //   typeof minutesToSchedule == "number" ? minutesToSchedule : 0;
      if (inPast >= 0) {
        if (item.state <= 0 || !item.state) {
          return "bg-danger text-white d-none";
        } else {
          // document.getElementById(item.schedule).disabled = true;
          return "d-none";
        }
      }
      if (item.state <= 0 || !item.state) {
        return "bg-danger text-white";
      }
    },
  },
};
</script>

<style lang="scss"></style>
